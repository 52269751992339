import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { PrivateAreaGuard } from './private-area.guard';
import { ViewComponent } from './view/view.component';

@NgModule({
    imports: [
        RouterModule.forChild([{
            path: 'view',
            component: ViewComponent,
            canActivate: [PrivateAreaGuard],
            children: [
                { path: '', pathMatch: 'full', redirectTo: 'home' },
                { path: 'companies', loadChildren: () => import('./company-area/company-area-routes').then(m => m.COMPANY_AREA_ROUTES) },
                { path: 'components-manager', loadChildren: () => import('./components-manager/components-manager-routes').then(m => m.COMPONENTS_MANAGER_ROUTES) },
                { path: 'environments', loadChildren: () => import('./environment-area/environment-area.module').then(m => m.EnvironmentAreaModule) },
                { path: 'expert-partners', loadChildren: () => import('./expert-partner-area/expert-partner-area-routes').then(m => m.EXPERT_PARTNER_AREA_ROUTES) },
                { path: 'home', loadChildren: () => import('./home-page/home-page-routes').then(m => m.HOME_PAGE_ROUTES) },
                { path: 'profile', loadChildren: () => import('./user-profile/user-profile-routes').then(m => m.PROFILE_ROUTES) }
            ]
        }])
    ],
    exports: [RouterModule]
})
export class PrivateAreaRoutingModule { }
