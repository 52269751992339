import { MediaMatcher } from "@angular/cdk/layout";
import { ChangeDetectorRef, Component, NgZone, OnDestroy, ViewChild } from "@angular/core";
import { MatMenuTrigger } from "@angular/material/menu";
import { Router } from "@angular/router";
import { Subscription } from "rxjs";
import { Domain, Environment, ThingDefinition, User, UserView } from "../../models";
import { QuickLink } from "../../models/quick-link";
import { UserNotification } from "../../models/user-notification";
import { AuthenticationService, BreadcrumbToken, ContextService, DialogService, DomainService, EnvironmentService, HeroSettings, NavbarButton, NotificationService, QuickLinkService, SidebarListItem, SocketService, UserNotificationService, UserService, UserViewService, ViewService } from "../../services";
import { Utilities } from "../../shared/utilities/utilities";
import { UserViewFormDialogComponent } from "../user-view/user-view-form-dialog.component";

@Component({
    selector: 'view',
    templateUrl: 'view.component.html'
})
export class ViewComponent implements OnDestroy {

    @ViewChild('userViewTrigger') userViewTrigger: MatMenuTrigger;

    mobileQuery: MediaQueryList;
    initiallyOpened: boolean;
    primaryButton: NavbarButton;
    secondaryButton: NavbarButton;
    heroConfig: HeroSettings;
    breadcrumbTokens: BreadcrumbToken[];
    environmentContext: Environment;
    thingDefContext: ThingDefinition;
    isDomainContext: boolean;
    domains: Domain[];
    sidebarVisible: boolean;
    sidebarItems: SidebarListItem[];
    progressBarVisible: boolean;
    showUserView: boolean;
    userViews: UserView[];
    selectedUserView: UserView;
    quickLinks: QuickLink[];
    notifications: UserNotification[];
    showNotificationBadge: boolean;
    selectedDomain: Domain;
    isComponentManager: boolean;

    private user: User;
    private subscriptions: Subscription[] = [];
    private _mobileQueryListener: () => void;

    constructor(
        private authenticationService: AuthenticationService,
        private contextService: ContextService,
        private socketService: SocketService,
        private zone: NgZone,
        private viewService: ViewService,
        private router: Router,
        private dialogService: DialogService,
        private userViewService: UserViewService,
        private notificationService: NotificationService,
        private quickLinkService: QuickLinkService,
        private userNotificationService: UserNotificationService,
        private userService: UserService,
        private domainService: DomainService,
        changeDetectorRef: ChangeDetectorRef,
        media: MediaMatcher
    ) {
        this.mobileQuery = media.matchMedia('(max-width: 600px)');
        this._mobileQueryListener = () => changeDetectorRef.detectChanges();
        this.mobileQuery.addEventListener('change', this._mobileQueryListener);
        this.initiallyOpened = !this.mobileQuery.matches;
        this.user = this.contextService.getUser();
        this.zone.runOutsideAngular(() => this.socketService.connect());
        this.subscriptions.push(this.viewService.primaryButton$.subscribe(button => this.primaryButton = button));
        this.subscriptions.push(this.viewService.secondaryButton$.subscribe(button => this.secondaryButton = button));
        this.subscriptions.push(this.viewService.heroConfig$.subscribe(hero => this.heroConfig = hero));
        this.subscriptions.push(this.viewService.breadcrumbTokens$.subscribe(tokens => this.breadcrumbTokens = tokens));
        this.subscriptions.push(this.viewService.environmentContext$.subscribe(environment => { this.environmentContext = environment; this.initUserViews(); }));
        this.subscriptions.push(this.viewService.thingDefContext$.subscribe(thingDef => this.thingDefContext = thingDef));
        this.subscriptions.push(this.viewService.isDomainContext$.subscribe(isDomainContext => { this.isDomainContext = isDomainContext; this.initDomains(); }));
        this.subscriptions.push(this.viewService.sidebarItems$.subscribe(sidebarItems => { this.sidebarVisible = !!sidebarItems; this.sidebarItems = sidebarItems }));
        this.subscriptions.push(this.viewService.progressBarVisible$.subscribe(progressBarVisible => this.progressBarVisible = progressBarVisible));
        this.subscriptions.push(this.viewService.isComponentManager$.subscribe(isComponentManager => this.isComponentManager = isComponentManager));
        this.showUserView = this.contextService.hasConfiguratorRole();
        this.initQuickLinks();
        this.initUserNotifications();
    }

    private initUserViews(): Promise<void> {
        this.selectedUserView = null;
        if (this.environmentContext && this.contextService.hasConfiguratorRole()) {
            return this.userViewService.getRecursivelyAll().then(userViews => {
                this.userViews = userViews;
                this.userViewService.enrichUserViews(userViews);
            }).catch(() => { /* do nothing */ });
        } else {
            this.userViews = null;
            return Promise.resolve();
        }
    }

    private initDomains(): void {
        if (this.isDomainContext) {
            this.selectedDomain = this.contextService.getDomain();
            this.domainService.getRecursivelyAll().then(domains => this.domains = domains);
        }
    }

    private initQuickLinks(): void {
        this.quickLinkService.getRecursivelyAll().then(quickLinks => this.quickLinks = quickLinks).catch(() => this.quickLinks = []);
    }

    private initUserNotifications(): void {
        this.userNotificationService.getRecursivelyAll().then(notifications => {
            this.notifications = notifications;
            const lastNotificationDisplayTimestamp = this.user.lastNotificationDisplayTimestamp || 0;
            this.notifications.forEach(n => {
                if (n.date > lastNotificationDisplayTimestamp) {
                    this.showNotificationBadge = true;
                    n.showWarning = true;
                }
                n.description = Utilities.truncateString(n.description, 128 - n.title.length);
            });
        }).catch(() => this.notifications = []);
    }

    getEnvTypeIcon(): string {
        if (this.environmentContext) {
            return EnvironmentService.getTypeIcon(this.environmentContext?.type);
        }
        return '';
    }

    isActiveItem(sidebarItem: SidebarListItem): boolean {
        if (sidebarItem.isNestedMenu) {
            return sidebarItem.nestedListItems.some(item => this.isActiveItem(item));
        }
        let currentRoute = this.router.url.split('?')[0];
        return currentRoute == sidebarItem.route || currentRoute.startsWith(sidebarItem.route + '/');
    }

    expandLocker(): void {
        this.viewService.lockerExpansion$.next(true);
    }

    profile(): void {
        this.router.navigate(['/view/profile']);
    }

    logout(): void {
        this.authenticationService.logout();
    }

    openEnvironment(): void {
        this.progressBarVisible = true;
        this.userViewService.navigate(this.selectedUserView).then(resp => {
            window.open(resp.url, '_blank');
            this.progressBarVisible = false;
        }).catch(err => {
            this.progressBarVisible = false;
            this.notificationService.showErrorMessage(err);
        });
    }

    navigateUrl(url: string): void {
        if (url) {
            window.open(url, '_blank');
        }
    }

    addUserView(): void {
        this.dialogService.open(UserViewFormDialogComponent).then(userView => {
            if (userView) {
                this.initUserViews().then(() => {
                    this.selectedUserView = userView;
                    this.contextService.setUserView(this.selectedUserView);
                });
            }
        });
    }

    selectUserView(userView: UserView, $event: MouseEvent): void {
        if (!userView.invalidUserType && !userView.invalidNetworkItem) {
            this.selectedUserView = userView;
            this.contextService.setUserView(this.selectedUserView);
        } else {
            $event.stopPropagation();
        }
    }

    unsetUserView(): void {
        this.selectedUserView = null;
        this.contextService.unsetUserView();
    }

    getUserViewTriggerTooltip(): string {
        if (this.selectedUserView) {
            return 'User View: ' + this.getNetworkItem(this.selectedUserView) + ' - ' + this.getNetworkName(this.selectedUserView);
        } else {
            return 'User View: None';
        }
    }

    getNetworkItem(userView: UserView): string {
        let networkType = 'User View';
        if (userView.organizationId) {
            networkType = 'Organization';
        } else if (userView.partnerId) {
            networkType = 'Partner';
        } else if (userView.customerId) {
            networkType = 'Customer';
        }
        const userType = userView.userType ? userView.userType.name : 'Invalid User Type'
        return `(${networkType}) ${userType}`;
    }

    getNetworkName(userView: UserView): string {
        if (userView.organizationId && userView.organization) {
            return userView.organization.name;
        } else if (userView.partnerId && userView.partner) {
            return userView.partner.name;
        } else if (userView.customerId && userView.customer) {
            if (userView.locationId) { // Location User
                if (userView.location) {
                    return `${userView.customer.name} - ${userView.location.name}`;
                } else {
                    return `${userView.customer.name} - Invalid Location`;
                }
            } else { // Customer User
                return userView.customer.name;
            }
        }
        return 'Invalid network';
    }

    editUserView(userView: UserView, $event: MouseEvent): void {
        this.dialogService.open(UserViewFormDialogComponent, { userView: userView }).then(userView => {
            if (userView) {
                this.userViewTrigger.closeMenu();
                this.initUserViews().then(() => {
                    // retrieving the enriched userview
                    const uv = this.userViews.find(uv => uv.id == userView.id);
                    this.selectedUserView = uv;
                    this.contextService.setUserView(this.selectedUserView);
                });
            }
        });
        $event.stopPropagation();
    }

    deleteUserView(index: number, $event: MouseEvent): void {
        let userView = this.userViews[index];
        this.userViewService.delete(userView.id).then(() => {
            this.notificationService.showInfoMessage('User View removed');
            this.userViews.splice(index, 1);
            if (this.selectedUserView?.id == userView.id) {
                this.selectedUserView = null;
                this.contextService.unsetUserView();
            }
        }).catch(err => this.notificationService.showErrorMessage(err));
        $event.stopPropagation();
    }

    updateUserLastNotificationDisplay(): void {
        this.userService.save({ lastNotificationDisplayTimestamp: new Date().getTime() }, this.user.id);
        this.notifications.forEach(n => n.showWarning = false);
        this.showNotificationBadge = false;
    }

    selectDomain(domain: Domain): void {
        if (this.isDomainChanging(domain)) {
            if (domain.default) {
                this.contextService.setDomain(null);
            } else {
                this.contextService.setDomain(domain);
            }
            this.viewService.domainChanged$.next(true);
            this.selectedDomain = domain;
        }
    }

    getDomainFaviconUrl(domain: Domain): string {
        const hostName = this.environmentContext?.hostName;
        if (!domain || domain.default) {
            return `https://${hostName}/favicon.ico?tenant=${hostName}`;
        }
        return `https://${domain.prefix}-${hostName}/favicon.ico?tenant=${domain.prefix}-${hostName}`;
    }

    private isDomainChanging(domain: Domain): boolean {
        if (domain.default) {
            return !!this.selectedDomain && !this.selectedDomain.default;
        }
        return domain.id != this.selectedDomain?.id;
    }

    ngOnDestroy(): void {
        this.socketService.disconnect();
        this.subscriptions.forEach(s => s.unsubscribe());
    }
}
