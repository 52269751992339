<!-- Toolbars -->
<mat-sidenav-container fullscreen>
	<!-- Sidenav -->
	<mat-sidenav #snav [mode]="mobileQuery.matches ? 'over' : 'side'" [fixedInViewport]="mobileQuery.matches" [opened]="initiallyOpened && sidebarVisible">
		<mat-nav-list>
			<mat-list-item class="side-menu-close" (click)="snav.close()">
				<mat-icon matListItemIcon>menu</mat-icon>
				<a matListItemTitle>Side Menu</a>
				<button mat-icon-button matListItemMeta>
					<mat-icon>close</mat-icon>
				</button>
			</mat-list-item>
			@for (item of sidebarItems; track item) {
				@if (!item.isNestedMenu) {
					<mat-list-item [ngClass]="{'is-active': isActiveItem(item)}" [routerLink]="item.route" queryParamsHandling="merge">
						<mat-icon matListItemIcon class="material-symbols-outlined">{{ item.icon }}</mat-icon>
						<a matListItemTitle>{{ item.label }}</a>
					</mat-list-item>
				}
				@if (item.isNestedMenu) {
					<mat-expansion-panel [class.mat-elevation-z0]="true">
						<mat-expansion-panel-header expandedHeight="48px" [ngClass]="{'is-active': isActiveItem(item)}">
							<mat-icon matListItemIcon class="material-symbols-outlined">{{ item.icon }}</mat-icon>
							<span>{{ item.label }}</span>
						</mat-expansion-panel-header>
						@for (subItem of item.nestedListItems; track subItem) {
							<mat-nav-list>
								<mat-list-item [ngClass]="{'is-active': isActiveItem(subItem)}" [routerLink]="subItem.route" queryParamsHandling="merge">
									<mat-icon matListItemIcon>fiber_manual_record</mat-icon>
									<a matListItemTitle>{{ subItem.label }}</a>
								</mat-list-item>
							</mat-nav-list>
						}
					</mat-expansion-panel>
				}
			}
		</mat-nav-list>
	</mat-sidenav>

	<!-- Sidenav content -->
	<mat-sidenav-content>
		<mat-toolbar class="servitly-sticky-toolbar">
			@if (!isComponentManager) {
				<!-- Topbar -->
				<mat-toolbar-row class="servitly-topbar align-items-center">
					<div class="logo d-flex align-items-center">
						<button mat-icon-button (click)="sidebarVisible ? snav.open() : null">
							<mat-icon [ngStyle]="{opacity: snav.opened || !sidebarVisible ? '0' : '1'}" class="text-white">menu</mat-icon>
						</button>
						<img src=" ../../../img/logo.png" height="40" [routerLink]="environmentContext ? '/view/companies/' + environmentContext.companyId : '/view/home'" />
					</div>
					@if (environmentContext) {
						<mat-divider [vertical]="true" class="h-50"></mat-divider>
					}

					<!-- Environment locker -->
					@if (environmentContext) {
						<div class="environment-context-locker environment-{{ environmentContext.type | lowercase }} d-flex flex-column">
							<span class="sy-overline text-white">{{ environmentContext.name }}</span>
							<div class="d-flex environment-type align-items-center">
								<mat-icon class="material-symbols-outlined environment-type-icon"> {{ getEnvTypeIcon() }}</mat-icon>
								<span class="sy-subtitle-2 d-flex align-items-center">
									{{ environmentContext.type | titlecase }}
								</span>
								@if (showUserView) {
									<div
										class="environemnt-locker-icon-container"
										#userViewTrigger="matMenuTrigger"
										[matMenuTriggerFor]="userViewMenu"
										matTooltipClass="full-width-tooltip"
										[matTooltip]="getUserViewTriggerTooltip()">
										<mat-icon class="material-symbols-outlined">{{ selectedUserView ? 'account_circle' : 'no_accounts' }}</mat-icon>
									</div>
								}
								<div class="environemnt-locker-icon-container" (click)="openEnvironment()" matTooltip="Open Environment in a new tab" matTooltipClass="full-width-tooltip">
									<mat-icon class="material-symbols-outlined">launch</mat-icon>
								</div>
								<!-- User View Menu -->
								<mat-menu #userViewMenu="matMenu" class="user-view-menu">
									<button mat-menu-item class="none-user-view-button" (click)="unsetUserView()" [ngClass]="{selected: !selectedUserView}">
										<mat-radio-button [checked]="!selectedUserView" />
										<span class="sy-body-2">None (Anonymous)</span>
									</button>
									@for (uv of userViews; track uv; let i = $index) {
										<button
											mat-menu-item
											class="user-view-button"
											(click)="selectUserView(uv, $event)"
											[ngClass]="{selected: selectedUserView?.id == uv.id, invalid: uv.invalidUserType || uv.invalidNetworkItem}">
											<mat-radio-button [disabled]="uv.invalidUserType || uv.invalidNetworkItem" [checked]="selectedUserView?.id == uv.id" />
											<div class="d-flex flex-column user-view-data">
												<span class="sy-subtitle-2" [ngClass]="{invalid: uv.invalidUserType}">{{ getNetworkItem(uv) }}</span>
												<span class="sy-body-2" [ngClass]="{invalid: uv.invalidNetworkItem}"> {{ getNetworkName(uv) }}</span>
											</div>
											<button mat-icon-button (click)="editUserView(uv, $event)" class="ms-auto" matTooltip="Edit">
												<mat-icon class="material-symbols-outlined">edit</mat-icon>
											</button>
											<button mat-icon-button (click)="deleteUserView(i, $event)" matTooltip="Delete">
												<mat-icon class="material-symbols-outlined" color="warn">delete</mat-icon>
											</button>
										</button>
									}
									<button mat-menu-item class="add-user-view-button" (click)="addUserView()">
										<mat-icon class="material-symbols-outlined">add</mat-icon>
										<span class="sy-body-2">Add User View</span>
									</button>
								</mat-menu>
							</div>
						</div>
					}

					<!-- Thing Def locker -->
					@if (thingDefContext && !mobileQuery.matches) {
						<div class="thing-def-context-locker d-flex flex-column">
							<span class="sy-overline text-white">Thing Definition</span>
							<div class="thing-def-name-container d-flex align-items-center">
								<div class="d-flex thing-def-name align-items-center">
									<mat-icon class="material-symbols-outlined">token</mat-icon>
									<span class="sy-subtitle-1">{{ thingDefContext.name }}</span>
								</div>
								<button mat-flat-button (click)="expandLocker()">Switch</button>
							</div>
						</div>
					}

					<!-- Domain locker -->
					@if (isDomainContext && domains?.length > 1) {
						<div class="domain-context-locker d-flex flex-column">
							<span class="sy-overline text-white">Domain</span>
							<div class="domain-name-container d-flex align-items-center">
								<div class="d-flex domain-name align-items-center">
									<mat-icon class="material-symbols-outlined">domain</mat-icon>
									<span class="sy-subtitle-2">{{ selectedDomain?.name || 'Default Domain' }}</span>
								</div>
								<div
									class="domain-locker-icon-container"
									matTooltipClass="full-width-tooltip"
									[matTooltip]="(!selectedDomain || selectedDomain.default ? '' : selectedDomain.prefix + '-') + environmentContext?.hostName"
									#domainTrigger="matMenuTrigger"
									[matMenuTriggerFor]="domainMenu">
									<img width="20" height="20" [src]="getDomainFaviconUrl(selectedDomain)" />
								</div>
							</div>
							<!-- Domain Menu -->
							<mat-menu #domainMenu="matMenu" class="domain-menu">
								@for (d of domains; track d; let i = $index) {
									<button mat-menu-item class="domain-button" (click)="selectDomain(d)" [ngClass]="{selected: selectedDomain?.id == d.id || (!selectedDomain && d.default)}">
										<mat-radio-group [ngModel]="selectedDomain?.id || 'default'">
											<mat-radio-button [value]="d.id" />
										</mat-radio-group>
										<div class="d-flex flex-column domain-data">
											<span class="sy-subtitle-2">{{ d.name }}</span>
											<span class="sy-body-2">{{ (d.default ? '' : d.prefix + '-') + environmentContext?.hostName }}</span>
										</div>
										<div class="domain-favicon ms-auto">
											<img width="20" height="20" [src]="getDomainFaviconUrl(d)" />
										</div>
									</button>
								}
							</mat-menu>
						</div>
					}

					<!-- Top right buttons -->
					<div class="d-flex align-items-center ms-auto">
						<!-- Notification button -->
						@if (notifications?.length) {
							<button mat-icon-button [matMenuTriggerFor]="notificationMenu" class="text-white" matTooltip="Notifications">
								<mat-icon
									matBadge="1"
									matBadgeColor="accent"
									matBadgePosition="above after"
									[matBadgeHidden]="!showNotificationBadge"
									aria-hidden="false"
									class="material-symbols-outlined empty-icon-badge">
									notifications
								</mat-icon>
							</button>
						}
						<mat-menu #notificationMenu="matMenu" class="topbar-menu" (closed)="updateUserLastNotificationDisplay()">
							@for (notification of notifications; track notification) {
								<button
									mat-menu-item
									class="topbar-menu-button topbar-menu-notification-button"
									[ngClass]="{'has-warning': notification.showWarning}"
									(click)="navigateUrl(notification.link)">
									<div class="sy-overline">{{ notification.date | date }}</div>
									<div class="notification-content">
										<span class="sy-subtitle-2">{{ notification.title }} </span>
										<span class="sy-body-2">{{ notification.description }}</span>
									</div>
								</button>
							}
						</mat-menu>

						<!-- Quick links button -->
						@if (quickLinks?.length) {
							<button mat-icon-button [matMenuTriggerFor]="quickLinkMenu" class="text-white" matTooltip="Quick Links">
								<mat-icon class="material-symbols-outlined">export_notes</mat-icon>
							</button>
						}
						<mat-menu #quickLinkMenu="matMenu" class="topbar-menu">
							@for (quickLink of quickLinks; track quickLink) {
								<button mat-menu-item class="topbar-menu-button" (click)="navigateUrl(quickLink.url)">{{ quickLink.label }}</button>
							}
						</mat-menu>

						<!-- Personal Area button -->
						<button mat-icon-button [matMenuTriggerFor]="menu" class="text-white" matTooltip="Personal Area">
							<mat-icon>account_box</mat-icon>
						</button>
						<mat-menu #menu="matMenu" class="topbar-menu">
							<button mat-menu-item class="topbar-menu-button" (click)="profile()">Profile</button>
							<button mat-menu-item class="topbar-menu-button" (click)="logout()">Logout</button>
						</mat-menu>
					</div>
				</mat-toolbar-row>
			} @else {
				<!-- Component Manager Top bar -->
				<mat-toolbar-row class="servitly-components-manager-topbar align-items-center">
					<div class="logo d-flex align-items-center">
						<img src=" ../../../img/logo_white.png" height="40" style="margin-left: 60px" />
					</div>

					<!-- title -->
					<div class="servitly-components-manager-topbar-title mx-auto">Custom Components Manager</div>

					<!-- Top right buttons -->
					<div class="d-flex align-items-center">
						<!-- Quick links button -->
						@if (quickLinks?.length) {
							<button mat-icon-button [matMenuTriggerFor]="quickLinkMenu" class="text-white" matTooltip="Quick Links">
								<mat-icon class="material-symbols-outlined">export_notes</mat-icon>
							</button>
						}
						<mat-menu #quickLinkMenu="matMenu" class="topbar-menu">
							@for (quickLink of quickLinks; track quickLink) {
								<button mat-menu-item class="topbar-menu-button" (click)="navigateUrl(quickLink.url)">{{ quickLink.label }}</button>
							}
						</mat-menu>
					</div>
				</mat-toolbar-row>
			}

			<!-- Navigation Bar -->
			<mat-toolbar-row class="servitly-navbar align-items-center">
				<div class="d-flex align-items-center overflow-hidden">
					@for (token of breadcrumbTokens; track token) {
						@if (!token.current) {
							<a class="servitly-breadcrumb-token sy-body-1" [routerLink]="token.routerLink" queryParamsHandling="merge">{{ token.label }}</a>
						}
						@if (token.current) {
							<span class="servitly-breadcrumb-token sy-body-1">{{ token.label }}</span>
						}
						@if (!token.current) {
							<mat-icon>chevron_right</mat-icon>
						}
					}
				</div>
				@if (secondaryButton) {
					<button
						mat-stroked-button
						color="primary"
						class="ms-auto"
						(click)="secondaryButton.callback()"
						[disabled]="!secondaryButton.enabled || secondaryButton.blockedByFeature"
						[blockedFeatureTooltip]="secondaryButton.blockedByFeature">
						@if (secondaryButton.blockedByFeature) {
							<mat-icon class="material-symbols-outlined" style="font-variation-settings: 'FILL' 1">lock</mat-icon>
						} @else if (secondaryButton.icon) {
							<mat-icon class="material-symbols-outlined">{{ secondaryButton.icon }}</mat-icon>
						}
						{{ secondaryButton.label }}
					</button>
				}
				@if (primaryButton) {
					<button
						mat-flat-button
						color="primary"
						[ngClass]="secondaryButton ? 'ms-3' : 'ms-auto'"
						(click)="primaryButton.callback()"
						[disabled]="!primaryButton.enabled || primaryButton.blockedByFeature"
						[blockedFeatureTooltip]="primaryButton.blockedByFeature">
						@if (primaryButton.blockedByFeature) {
							<mat-icon class="material-symbols-outlined" style="font-variation-settings: 'FILL' 1">lock</mat-icon>
						}
						{{ primaryButton.label }}
					</button>
				}
			</mat-toolbar-row>

			@if (progressBarVisible) {
				<mat-progress-bar mode="indeterminate" color="accent" />
			}
		</mat-toolbar>

		<!-- Hero -->
		@if (heroConfig) {
			<mat-toolbar class="servitly-hero">
				<div class="d-flex flex-column h-100 justify-content-between">
					<span class="servitly-hero-title sy-headline-4">{{ heroConfig.title }}</span>
					<span class="servitly-hero-subtitle sy-body-2">{{ heroConfig.description }}</span>
				</div>
				@if (heroConfig.editButton) {
					<button mat-icon-button class="text-white ms-auto align-self-end" (click)="heroConfig?.editButton.callback()" [matTooltip]="heroConfig?.editButton.tooltip">
						<mat-icon>edit</mat-icon>
					</button>
				}
				@if (heroConfig.helpUrl) {
					<button
						mat-icon-button
						[ngClass]="{'ms-auto': !heroConfig.editButton}"
						class="text-white align-self-end"
						(click)="navigateUrl(heroConfig.helpUrl)"
						matTooltip="Explore more at Help Center">
						<mat-icon
							class="material-symbols-outlined"
							style="
								font-variation-settings:
									'FILL' 1,
									'wght' 700;
							"
							>help</mat-icon
						>
					</button>
				}
			</mat-toolbar>
		}

		<!-- page content-->
		<router-outlet />
	</mat-sidenav-content>
</mat-sidenav-container>
